
// Override bootstrap variables
@import 'variables.scss';

// Import bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// Custom styling
@import 'custom.scss';
@import 'form-sendinblue.scss';


//components
@import "component/button";
@import "component/navigation";
@import "component/footer.scss";

// base
// @import "base/base";
// @import "base/animation";
@import "base/typography";
// @import "base/utilities";

//Typography
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// Responsive
@import 'responsive.scss';

// Animation scroll me
@import 'base/animation';