// Configurations
$enable-shadows:                false;
$enable-gradients:              false;
$enable-responsive-font-sizes:  true;

// Colors
 $primary: #2F2F2F;
 $orange-codeploy: #E87E2E;
 $blue-codeploy: #2C627C;
 $yellow-codeploy: #FECC33;
 $light-blue-back: #F4F7F8;
 $grey-text: #5b5675; 


// $spacer: 1rem;

// $box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

// Typography
$h-font-family: 'Nunito', sans-serif;
$font-family-sans-serif: 'Montserrat', sans-serif;


// Border radius
$border-radius: 1rem;

// Button
// $btn-border-radius: 1rem;
// $btn-border-width: 2px;
// $btn-font-weight: bold;


// 1em = 16px
// */

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 600px) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 900px) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 1200px) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 1800) { @content };    //1800
    }
}