// Footer

.footer-section {
	background-color: $blue-codeploy;
	padding: 70px 90px 10px 90px;
	&-info {
		padding: 0px 60px;
	}
	&-text {
		padding-left: 0px;
		margin-top: 20px;
		letter-spacing: .5px;
	}
	&-item {
		color: #fff;
		padding-left: 0px;
		font-size: 15px;
		padding-bottom: 15px;
	}
	&-item a {
		color: #fff;
		margin-left: 5px;
	}
	&-item a:hover {
		color: $orange-codeploy;
		transition-duration: .8s;
	}
	&-button {
		margin-top: 30px;
	}
	.illustration-section p {
		color: #fff;
		font-size: 13px;
		margin-top: 20px;
		font-style: italic;
		a {
			color: $orange-codeploy;
		}
	}
}



@media (min-width: 320px) and (max-width: 767px) {
    .footer-section {
        padding: 50px 15px;
        &-logo {
			padding-left: 30px;
		}
        &-info {
            padding: 10px 30px;
        }
        &-text {
            padding-left: 0px;
            margin-top: 20px;
            letter-spacing: .5px;
        }
        &-item {
            color: #fff;
            padding-left: 0px;
            font-size: 15px;
            padding-bottom: 15px;
        }
        &-item a {
            color: #fff;
            margin-left: 5px;
        }
        &-button {
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }
    
}





