.button {
	margin-top: 40px;
}
a:hover {
	text-decoration: none;
	color: inherit;
}
.cta {
	position: relative;
	margin: auto;
	padding: 19px 22px;
	transition: all 0.2s ease;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		border-radius: 28px;
		background: rgba($yellow-codeploy,.8);
		width: 56px;
		height: 56px;
		transition: all 0.3s ease;
	}
	span {
		position: relative;
		font-size: 16px;
		line-height: 18px;
		font-weight: 700;
		letter-spacing: 3px;
		vertical-align: middle;
		color: $blue-codeploy;
		font-family: $h-font-family;
	}
	svg {
		position: relative;
		top: 0;
		margin-left: 10px;
		fill: none;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke: $blue-codeploy;
		stroke-width: 2;
		transform: translateX(-5px);
		transition: all 0.3s ease;
	}
	&:hover {
		&:before {
			width: 100%;
			background: $yellow-codeploy;
      		text-decoration: none;
		}
		svg {
			transform: translateX(0);
		}
	}
	&:active {
		transform: scale(0.96);
	}
}




.cta-footer {
	position: relative;
	margin: auto;
	padding: 15px 22px;
	transition: all 0.2s ease;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		border-radius: 28px;
		background: rgba($orange-codeploy,.8);
		width: 48px;
		height: 48px;
		transition: all 0.3s ease;
	}
	span {
		position: relative;
		font-size: 16px;
		line-height: 18px;
		font-weight: 700;
		letter-spacing: 3px;
		vertical-align: middle;
		color: #fff;
		font-family: $h-font-family;
	}
	svg {
		position: relative;
		top: 0;
		margin-left: 10px;
		fill: none;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke: #fff;
		stroke-width: 2;
		transform: translateX(-5px);
		transition: all 0.3s ease;
	}
	&:hover {
		&:before {
			width: 100%;
			background: $orange-codeploy;
      		text-decoration: none;
		}
		svg {
			transform: translateX(0);
		}
	}
	&:active {
		transform: scale(0.96);
	}
}



@media (min-width: 320px) and (max-width: 767px) {
	.cta {
		transition: all 0s ease;
		&:before {
			transition: all 0s ease;
		}
		
		&:hover {
			&:before {
				width: 48px;
				background: $yellow-codeploy;
				text-decoration: none;
			}
			svg {
				transform: translateX(0);
			}
		}
		&:active {
			transform: scale(0);
		}
}


.cta-footer {
	transition: all 0s ease;
	&:before {
		transition: all 0s ease;
	}

	&:hover {
		&:before {
			width: 48px;
			background: $orange-codeploy;
      		text-decoration: none;
		}
		svg {
			transform: translateX(0);
		}
	}
	&:active {
		transform: scale(0);
	}
}


}