html {
	scroll-behavior: smooth;

}

/* Custom Styling */

.header {
	height: 100vh;
	position: relative;
	margin-right: 50px;
	margin-left: 50px;


	&__logo-box {
		position: absolute;
		top: 40px;
		left: 20px;
		a {
			height: 100px;
			width: 200px;
			padding: 20px;
		}
	}

	&__logo {
		height: 45px;
	}

	&__img img {
		animation: float 3s ease-in-out infinite;
	}

	&-row {
		margin: 0;
		position: absolute;
		top: 60%;
		-ms-transform: translateY(-60%);
		transform: translateY(-60%);
	}


}
li {
	color: $grey-text;
}
.header-servizi {
	position: relative;
	margin-right: 50px;
	margin-left: 50px;
}

.header-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.cover-page {
	&-text {
		padding-left: 30px;
	}
}

.center-section {
	background-color: $blue-codeploy;
	padding: 60px 100px;
	margin-top: 50px;
	margin-bottom: 150px;
}


.description-section {
	margin-top: 60px;
	margin-bottom: 60px;
}

.description-section-review {
	margin-top: 100px;
	margin-bottom: 100px;
}



// product section with background
.product-section {
	&-back {
		background-color: $blue-codeploy;
		padding-top: 30px;
		padding-bottom: 30px;
		margin-bottom: 40px;
	}

}


.culture-section {
	padding: 80px 100px;

	h2 {
		font-size: 50px;
		padding-left: 40px;
	}
}

// product section without background
.product-section {
	padding-top: 80px;
	padding-bottom: 80px;
}


// card section with icon
.card-section {
	margin-bottom: 100px;

	&-row {
		margin-bottom: 40px;
	}

	&-single {
		padding-left: 30px;
		padding-right: 30px;

		&-h3 {
			color: $blue-codeploy;
			font-size: 20px;
			font-weight: 600;
		}

		&-img {
			margin-bottom: 10px;
		}

		&-p {
			font-size: 14px;
		}
	}
}

// i nostri servizi - homepage

.service {
	&-title-h2 {
		color: $blue-codeploy;
		font-weight: 600;
		margin-bottom: 50px;
	}
	&-row {
		margin-bottom: 80px;
	}
	&-shadow {
		-webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		-moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		padding: 30px 30px;
		border-radius: 20px;
		// min-height: 250px;
		// max-height: 250px;
	}
	&-img {
		margin-top: -80px;
	}
	&-title-box {
		color: $blue-codeploy;
		font-size: 24px;
		font-weight: 600;
		margin-top: 10px;
	}
}

// banner progetti homepage
.project-banner {
	margin-bottom: 150px;

	&-text h2 {
		color: $blue-codeploy;
		font-weight: 600;
		font-size: 40px;
		padding-right: 50px;
	}
}

// project card section homepage
.section-project-card {
	margin-bottom: 40px;

	&-single {
		padding: 10px 40px;

		&-img {
			margin-bottom: 25px;
		}

		&-p {
			color: $orange-codeploy;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 14px;
			margin-bottom: 5px;
		}

		&-link {
			color: $blue-codeploy;
			font-weight: 600;
			font-size: 22px;
		}

		&-link:hover {
			color: $orange-codeploy;
			transition-duration: .8s;
		}
	}
}

.section-project {
	&-button {
		margin-bottom: 120px;
	}
}

// project card page
.project-card {
	// margin-top: 20px;
	margin-bottom: 100px;

	&-row {
		-webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		-moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		padding: 60px 40px;
		margin-bottom: 60px;
	}

	&-h4 {
		color: $orange-codeploy;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 3px;
		font-family: $font-family-sans-serif;
	}

	&-hr {
		color: $orange-codeploy;
		border: 1px solid;
		border-radius: 100px;
		max-width: 30px;
		margin: 0px;
	}

	&-h2 {
		color: $blue-codeploy;
		font-weight: 600;
		padding-top: 15px;
	}
}


//page single project
.project-logo {
	margin-bottom: 20px;
}

.project-page-h4 {
	color: $orange-codeploy;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 3px;
	font-family: $font-family-sans-serif;
	margin-bottom: 10px;
}

// icon section home page
.icon-section {
	margin-top: 150px;
	margin-bottom: 150px;

	&-text {
		padding: 20px;

		&-h2 {
			color: $blue-codeploy;
			font-weight: 600;

		}
	}

	&-box-single {
		border-radius: 60px;
		-webkit-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		-moz-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		padding: 40px 5px;
		margin-left: 20px;
		margin-right: 20px;
	}

	&-box-h3 {
		color: $blue-codeploy;
		font-size: 24px;
		padding-top: 15px;
	}
}

.bottom {
	margin-top: 60px;
}


.brick-section {
	border-radius: 60px;
	-webkit-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	-moz-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	padding: 20px 40px;
	margin-top: 150px;
	margin-bottom: 100px;
}

// review carousel
.review-carousel {
	padding-top: 10px;
}

.review-carousel i {
	color: $blue-codeploy;
	font-size: 25px;
}

.review-carousel-text p {
	padding-right: 90px;
	padding-left: 90px;
}

.review-carousel-text .name-review {
	color: $blue-codeploy;
	font-style: italic;
	font-weight: 600;
}



.quote-section {
	.description-section-p {
		font-style: italic;
		line-height: 25px;
	}

	.description-section-h2 {
		font-style: italic;
		font-size: 22px;
	}

	.quote-sign {
		font-size: 30px;
		color: $blue-codeploy;
		font-weight: 700;
		font-family: $h-font-family;
		font-style: italic;
		line-height: 25px;
	}
}


// navigazione progetti
.nav-project {
	margin-bottom: 150px;

	.nav-project-center-title h2 {
		color: $blue-codeploy;
		font-weight: 700;
		padding-bottom: 40px;
	}

	.nav-project-box-text {
		border-radius: 20px;
		-webkit-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		-moz-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		padding: 50px;

		.nav-project-title {
			color: $orange-codeploy;
			text-transform: uppercase;
			font-size: 17px;
			font-weight: 400;
			letter-spacing: 2px;
			font-family: $font-family-sans-serif;
		}

		.nav-project-maintitle {
			color: $blue-codeploy;
			font-weight: 600;

		}
	}
}

// method
.method-container {
	margin-top: 70px;
}
.method-box {
	-webkit-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	-moz-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	border-radius: 40px;
	padding: 50px 50px 60px;
	margin-bottom: 90px;
	img {
		position: absolute;
		top: -35px;
		left: -15px;
	}
	&-h {
		color: $blue-codeploy;
		font-weight: 600;
		margin-bottom: 15px;
	}
}

// Blog

.header-blog {
	height: 70vh;
	position: relative;
	margin-right: 50px;
	margin-left: 50px;
}

.blog-text {
	margin-top: 60px;
}

.blog-card {
	margin-bottom: 200px;

	&-btn {
		background-color: $orange-codeploy;
		font-family: $h-font-family;
		color: #fff;
		padding: 5px 20px;
	}

	&-btn:hover {
		background-color: $blue-codeploy;
		color: #fff;
		transition: 1s ease-in;
	}
}

.blog-card-single {
	padding: 0px;
	margin-bottom: 40px;
	padding-bottom: 15px;
	border-radius: 20px;
	-webkit-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	-moz-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
	box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);

	&-img {
		width: 100%;
		height: 203px;
		border-radius: 20px 20px 0px 0px;
		object-fit: contain;
	}

	&-body {
		padding: 28px;
	}

	&-title {
		color: $blue-codeploy;
		font-weight: 600;
		font-size: 25px;
	}
}

// blog dettaglio


.heading-secondary {
	font-size: 24px;
	font-weight: 300;
}
.blog_img_div {
	margin-top: 30vh;
	padding-left: 5vw;
}

.blog_title {
	padding-left: 6vw;
	margin-top: 40vh;
}

.blog_description {
	max-width: 130vw;
	padding-top: 10vh;
	margin-bottom: 10vh;
	padding-right: 20vw;
	padding-left: 20vw;
}

.blog_img {
	max-width: 100%;
	max-height: 50vh;
	border-radius: 30px;
}


.pagination {
	display: inline-block;
}

.pagination a {
	color: $blue-codeploy;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
}

.pagin_button {
	border: none;
	background-color: #ffffff;
	outline: none;
}



@keyframes float {
	0% {

		transform: translatey(0px);
	}

	50% {

		transform: translatey(-10px);
	}

	100% {

		transform: translatey(0px);
	}
}






// pagine servizi

.servizi-container {
	padding-top: 170px;
}
.servizi-title {
	color: $blue-codeploy;
	font-family: $h-font-family;
	font-weight: 700;
}
.servizi-subtitle {
	color: $orange-codeploy;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 20px;
}
.servizi-description {
	color: $grey-text;
	padding-top: 40px;
	padding-bottom: 50px;
}

.bullet-list ul li::before {
	content: "\2022";
	color: $orange-codeploy;
	font-weight: bold;
	width: 1em;
	margin-left: -1em;
	display: inline-block;
  }

.servizi-description-bold {
	color: $blue-codeploy;
	font-weight: 700;
}

.servizi-project {
	margin-top: 80px;
	margin-bottom: 150px;
	&-row {
		margin-top: 60px;
	}
	&-box {
		border-radius: 30px;
		-webkit-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		-moz-box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		box-shadow: -1px 2px 30px 3px rgba(214, 222, 255, 0.71);
		// padding: 20px 40px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 30px;

		img {
			width: 100%;
			border-radius: 30px 30px 0px 0px;
			object-fit: contain;
		  }
	}
	&-title {
		color: $orange-codeploy;
		text-transform: uppercase;
		padding-top: 25px;
		letter-spacing: 2px;
		padding-left: 40px;
	}
	&-maintitle {
		color: $blue-codeploy;
		font-weight: 700;
		padding-left: 40px;
		padding-right: 40px;
	}
	&-button {
		padding-left: 40px;
		padding-bottom: 40px;
	}
}
.servizi-project h2 {
	color: $blue-codeploy;
	font-weight: 700;
}

#cloud   {
    margin-top: 0px !important;
    margin-bottom: 150px;
}







// pagine corsi
.servizi-title-training {
	color: $blue-codeploy;
	font-family: $h-font-family;
	font-weight: 700;
	font-size: 45px;
	padding-bottom: 30px;
}
.goals-section {
	margin-top: 50px;
	padding-bottom: 70px;
}
.goals-section h2 {
	color: $orange-codeploy;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 20px;
	margin-bottom: 50px;
}
.goals-box-shadow {
	-webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		-moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
		padding: 30px 30px;
		border-radius: 40px;
		background-color: #fff;
}
.goals-box {
	&-text p {
		margin-left: 0px;
		margin-bottom: 0px;
	}
}
.info-training {
	margin-top: 50px;
	margin-bottom: 150px;
}
.info-training h2 {
	color: $orange-codeploy;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 20px;
	margin-bottom: 20px;
}
.info-training li {
	background: url('../img/corsi/arrow.png') no-repeat left center;
	background-size: 20px;
    padding: 5px 10px 5px 40px;
    list-style: none;
    margin: 0;
    vertical-align: middle;
	margin-bottom: 15px;
}

.program-section {
	-webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
	-moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
	box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.06);
	padding: 60px 30px;
	border-radius: 40px;
	
	// padding-bottom: 80px;

	h2 {
		color: $orange-codeploy;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 20px;
	padding-bottom: 30px;
	}

	.program-list li {
	background: url('../img/corsi/check.png') no-repeat left center;
	background-size: 20px;
    padding: 5px 10px 5px 40px;
    list-style: none;
    margin: 0;
    vertical-align: middle;
	margin-bottom: 15px;
	}
}

.training-partner {
	padding-top: 100px;
	margin-bottom: 150px;
}
.training-partner h2 {
	color: $orange-codeploy;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 20px;
	padding-bottom: 20px;
}

// pagina codesign
#codesign h1 {
	font-size: 70px;
	padding-bottom: 20px;
}
#step .goals-box-shadow {
	margin-bottom: 50px;
	h3 {
		color: $blue-codeploy;
		font-size: 23px;
		font-weight: 600;
	}
}
#step .goals-box-img img {
	margin-left: 20px;
	margin-top: 25px;
}
#risultati {
	background-color: $blue-codeploy ;
	margin-bottom: 80px;
	padding: 60px 100px;
	.goals-box {
		margin-bottom: 40px;
	}
	h2 {
		color: $yellow-codeploy;
	}
	h4 {
		color: $orange-codeploy;
		font-weight: 600;
		font-size: 22px;
	}
	img {
		margin-left: 25px;
		margin-top: 18px;
	}
}
#soluzione {
	opacity: 0.8;
}
#valori {
	padding-top: 60px;
	margin-bottom: 100px;
	h2 {
		padding-bottom: 10px;
	}
	li {
		font-size: 19px;
		padding-bottom: 15px;
	}
}

