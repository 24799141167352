#sib-container {
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.06);
		-moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.06);
		box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.06);
    border-radius: 40px;
    padding: 40px 50px;
    margin-bottom: 100px;
}
.form-title {
  color: $blue-codeploy;
  font-weight: 700;
}
.form-text {
  font-family: $font-family-sans-serif;
}
.sib-form .entry__field {
  border-radius: 20px;
}
.sib-form .input:first-child, 
.sib-form .input__affix:first-child {
  padding-left: 20px;
}
.sib-form-block__button {
  border-radius: 30px;
  background-color: $orange-codeploy;
  color: #fff;
  padding: 10px 30px;
  font-family: $h-font-family;
  font-weight: 600;
  letter-spacing: 2px;
}
.sib-optin {
  margin-left: 25px;
  font-family: $font-family-sans-serif;
}
.sib-optin a {
  text-decoration: none;
  color: $blue-codeploy;
}
.sib-form .input_replaced:checked+.checkbox_tick_positive {
  background: $orange-codeploy;
  border-color:  $orange-codeploy;
}


  
@media (min-width: 320px) and (max-width: 767px) {
  #sib-container {
    padding: 30px 30px;
  }
}
