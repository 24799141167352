
.menu {
    margin-top: 50px;
    padding-right: 100px;
    position: absolute;
    width: 100%;
    text-align: right;
    // padding: 8px 2rem;
    height: 70px;
    font-family: $h-font-family;
    z-index: 1000;
  }
  .menu ol {
    padding-left: 0px;
  }
  .logo {
      float: left;
      position: absolute;
      top: 10px;
      left: 2rem;
      width: 60px;
      height: 50px;
  }
  .search {
      float: right;
      position: absolute;
      top: 25px;
      right: 2rem;
      stroke: #333;
      width: 20px;
      height: 20px;
  }
  .menu-item {
    list-style-type: none;
    display: inline;
    position: relative;
    margin: 15px;
    color: $blue-codeploy;
    font-family: $h-font-family;
  }
  .menu-item a {
      color: $blue-codeploy;
      font-weight: 600;
      font-size: 18px;
  }
  
  .menu-item:before {
    position: absolute;
    content: "";
    border-bottom: 3px solid #FECC33;
    border-radius: 30px;
    overflow: hidden;
    width: 0%;
    left: 50%;
    top: 30px;
    transition: 0.2s ease-in-out 0.15s;
  }
  
  .menu-item:hover:before {
    width: 100%;
    left: 0%;
    transition: 0.2s ease-in-out;
  }
  
  .sub-menu {
    position: absolute;
    left: 0%;
    background: #fff;
    font-family: $h-font-family;
    top: 50px;
    padding: 0;
    text-align: left;
    box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.15);
    max-height: 0;
    width: 250px;
    opacity: 0;
    transition: 0.45s ease all 0.1s;
    z-index: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.1s;
    visibility: hidden;
  }
  .menu-item:nth-child(n):hover .sub-menu {
    max-height: 400px;
    max-width: 250px;
    opacity: 1;
      -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    transition-delay: 0.15s;
    visibility: visible;
  }
  .sub-menu .menu-item {
    display: block;
  }
  
  .menu-item:nth-child(n) .plus-icon {
    width: 15px;
    height: 15px;
    stroke: #333;
    margin-left: 2px;
    margin-bottom: -5px;
  }
  
  .menu-item:nth-child(n) .sub-menu-item {
    list-style-type: none;
    display: block;
    margin: 15px;
    position: relative;
    opacity: 0;
    transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.1s;
    visibility: hidden;
  }
  .menu-item:nth-child(n):hover .sub-menu {
    max-height: 400px;
    max-width: 250px;
    opacity: 1;
  }
  .menu-item:nth-child(n):hover .sub-menu-item a {
    transition: 0.15s ease all;
  }
  .menu-item:nth-child(n):hover .sub-menu-item a:hover {
    color: $orange-codeploy;
  }
  .menu-item:nth-child(n):hover .sub-menu-item {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    transition-delay: 0.15s;
    visibility: visible;
  }
  













// HAMBURGER MENU

.navigation {
    &__checkbox {
        display: none;
    }

    &__button {
        background-color: $blue-codeploy;
        height: 70px;
        width: 70px;
        position: fixed;
        top: 25px;
        right: 60px;
        border-radius: 50%;
        z-index: 2000;
        box-shadow: 0 10px 30px rgba($orange-codeploy, .1);
        text-align: center;
        cursor: pointer;

        @include respond(tab-port) {
            top: 40px;
            right: 40px;
        }

        @include respond(phone) {
            top: 35px;
            right: 35px;
            height: 60px;
            width: 60px;
        }
    }

    &__background {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        position: fixed;
        top: 25px;
        right: 65px;
        background-color: $blue-codeploy;
        z-index: 1100;
        transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);

        //transform: scale(80);

        @include respond(tab-port) {
            top: 45px;
            right: 45px;
        }

        @include respond(phone) {
            top: 40px;
            right: 40px;
            height: 50px;
            width: 50px;
        }
    }

    &__nav {
        height: 100vh;
        position: fixed;
        top: 50vh;
        left: 10vw;
        z-index: 1500;
        opacity: 0;
        visibility: hidden;
        width: 0;
        transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        list-style: none;
        text-align: left;
        width: 100%;
        
        @include respond(phone) {
            padding-left: 0px;
        }
    }
    &__contact__img {
        margin-bottom: 20px;
    }
    &__contact__item {
        // border-left: 2px solid #fff;
        padding-left: 20px;
        color: #fff;
        margin-bottom: 7px;
        h4 {
            font-weight: bold;
            font-size: 36px;
            letter-spacing: 4px ;
        }
        a {
            color: #fff;
            font-size: 19px;
            padding-right: 10px;
        }
        
    }
    &__contact__social {
        padding-top: 28px;
    }
    &__item {
        margin: 5px 10px;
    }

    &__link {
        display: inline-block;
        font-size: 30px;
        letter-spacing: 4px;
        font-family: $h-font-family;
        font-weight: 700;
        padding: 10px 20px;
        color: $light-blue-back;
        text-decoration: none;
        margin-bottom: 0px;
        &:link,
        &:visited {
            
            @include respond(phone) {
                font-size: 30px;
            }

            span {
                margin-right: 15px;
                display: inline-block;
            }
        }
        
        &:hover,
        &:active {
            background-position: 100%;
            color: $orange-codeploy;
            transform: translateX(10px);

            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
            border-radius: 40px;
            background-size: 220%;
            transition: all .4s;
        }
    }


    //FUNCTIONALITY
    &__checkbox:checked ~ &__background {
        transform: scale(80);
    }

    &__checkbox:checked ~ &__nav {
        opacity: 1;
        visibility: visible;
        width: 100%;
    }


    //ICON
    &__icon {
        position: relative;
        margin-top: 35px;

        @include respond(phone) {
            margin-top: 30px;
        }

        &,
        &::before,
        &::after {
            width: 34px;
            height: 4px;
            border-radius: 15px;
            background-color: white;
            display: inline-block;

        @include respond(phone) {
            height: 3px;
            width: 30px;
        }

        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s;
        }

        &::before { top: -9px; }
        &::after { top: 9px; }
    }

    &__button:hover &__icon::before {
        top: -11px;
    }

    &__button:hover &__icon::after {
        top: 11px;
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon::before {
        top: 0;
        transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}

.navigation__item i {
    font-size: 22px;
}
.navigation__link__dropdown .navigation__link__dropdown__open {
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    font-family: $h-font-family;
    text-decoration: none;
  }



// DROPDOWN

.navigation__item:hover .navigation__link__dropdown,
  .navigation__link__dropdown {
    height: 6rem;
  }
  
  .navigation__link__dropdown,
  .navigation__link:hover .navigation__link__dropdown {
    height: 0;
    overflow: hidden;
    transition: height 0.25s;
  }
  .navigation__link__dropdown__li {
    margin-bottom: 12px;
}
  .navigation__link__dropdown a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    
  }
  .navigation__link__dropdown a::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 0.3rem;
    background: $yellow-codeploy;
    bottom: -0.2rem;
    transform: scale(0, 1);
    border-radius: 3rem;
    transition: transform 0.3s;
    transform-origin: right;
  }
  .navigation__link__dropdown a:hover::after {
    transform-origin: left;
    transform: scale(1, 1);
  }


// DROPDOWN - second

.navigation__link__dropdown__second .navigation__link__dropdown__open {
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    font-family: $h-font-family;
    text-decoration: none;
  }
  .navigation__item:hover .navigation__link__dropdown__second,
  .navigation__link__dropdown__second {
    height: 21rem;
  }
  
  .navigation__link__dropdown__second,
  .navigation__link:hover .navigation__link__dropdown__second {
    height: 0;
    overflow: hidden;
    transition: height 0.25s;
  }

 
  .navigation__link__dropdown__second a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    
  }
  .navigation__link__dropdown__second a::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 0.3rem;
    background: $yellow-codeploy;
    bottom: -0.2rem;
    transform: scale(0, 1);
    border-radius: 3rem;
    transition: transform 0.3s;
    transform-origin: right;
  }
  .navigation__link__dropdown__second a:hover::after {
    transform-origin: left;
    transform: scale(1, 1);
  }





  @media (min-width: 320px) and (max-width: 767px) {
      .navigation__contact__col {
          visibility: hidden;
      }
      .menu {
        visibility: hidden;
      }

      .navigation__item:hover .navigation__link__dropdown__second,
      .navigation__link__dropdown__second {
        height: 20rem;
      }
      
      .navigation__link__dropdown__second,
  .navigation__link:hover .navigation__link__dropdown__second {
    height: 0;
    overflow: hidden;
    transition: height 0.25s;
  }
  }