@media screen and (min-width: 1800px) {
  .heading-primary {
    font-size: 80px;
    font-weight: 700;
    
}
.header-row p {
  font-size: 20px;
}
.header__img img {
  width: 650px;
}
}


@media (min-width: 320px) and (max-width: 767px) {

  // cover
  .header {
    height: 95vh;
    margin-right: 20px;
    margin-left: 20px;

    &__logo-box {
      top: 50px;
      left: 20px;
    }

    &__img img {
      margin-top: 30px;
    }

    &__logo {
      height: 35px;
    }

    &-row {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &-center {
      top: 70%;
      -ms-transform: translateY(-70%);
      transform: translateY(-70%);
    }

    .heading-primary {
      font-size: 35px;
      margin-top: 160px;

      &-center {
        font-size: 30px;
        color: $blue-codeploy;
        font-weight: 700;
        margin-top: 30px;
      }
    }

    .project-logo {

      font-size: 10px;
      margin-top: 200px;

    }

    .project-page-h4 {

      font-size: 10px;
      margin-top: 10px;

    }

  }

  .cover-page {
    &-text {
      padding-left: 0px;
    }
  }

  .center-title {
    padding-bottom: 0px;
    margin-top: 0px;
  }


  .center-section {
    padding: 40px 40px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .padding-mobile {
    padding: 40px 60px;
  }

  .description-section {
    margin-top: 0px;
    margin-bottom: 60px;

    img {
      width: 300px;
      margin-bottom: 20px;
    }

    &-h2 {
      padding-left: 20px;
    }

    &-p {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .description-section-review {
    margin-top: 0px;
    margin-bottom: 60px;

    &-h2 {
      padding-left: 20px;
    }

    &-p {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .description-section-review .img-fluid {
    width: 100px;
    margin-bottom: 20px;
  }

  // icon section home page
  .icon-section {
    margin-top: 0px;
    margin-bottom: 30px;

    &-text {
      padding: 20px;
    }

    &-box-single {
      border-radius: 0px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      padding: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    &-box-img {
      width: 60px;
    }

    &-box-h3 {
      font-size: 18px;
      padding-top: 15px;
    }
  }

  // i nostri servizi - homepage

.service {
	&-title-h2 {
		margin-bottom: 60px;
	}
	&-row {
		margin-bottom: 0px;
	}
	&-shadow {
		padding: 30px 30px;
    margin-bottom: 50px;
	}
	&-img {
		margin-top: -80px;
	}
	&-title-box {
		color: $blue-codeploy;
		font-size: 24px;
		font-weight: 600;
		margin-top: 10px;
	}
}

  .bottom {
    margin-top: 0px;
  }

  .project-banner {
    &-text h2 {
      font-size: 30px;
      padding-left: 20px;
      padding-top: 20px;
    }
  }

  .card-section {
    margin-bottom: 50px;

    &-single {
      padding-left: 5px;
      padding-right: 5px;

      &-h3 {
        font-size: 18px;
        font-weight: 600;
      }

      &-img {
        margin-bottom: 10px;
        width: 60px;
      }

      &-p {
        font-size: 12px;
      }
    }
  }

  .brick-section {
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 20px 30px;
    margin-top: 100px;
    margin-bottom: 0px;
  }

  .product-section {
    padding-top: 40px;
    padding-bottom: 40px;

    &-img {
      margin-top: 30px;

      img {
        width: 300px;
      }
    }
  }

  // review carousel
  .clients-logo img {
    width: 120px;
    padding-bottom: 10px;
  }

  .review-carousel {
    padding-top: 0px;
  }

  .arrow-right {
    margin-left: 60px;
  }

  .arrow-left {
    margin-right: 60px;
  }

  .review-carousel-text p {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 15px;
  }

  .review-carousel-text .name-review {
    color: $blue-codeploy;
    font-style: italic;
    font-weight: 600;
  }



  // product section with background
  .product-section-back-text {
    &-h4 {
      font-size: 14px;
      padding-left: 10px;
    }

    &-h2 {
      font-size: 30px;
      padding-right: 20px;
      padding-left: 10px;
    }
  }

  .culture-section {
    padding: 80px 30px;

    h2 {
      font-size: 40px;
      padding-left: 0px;
    }
  }

  // product section without background
  .product-section-text {
    padding-left: 20px;

    &-h4 {
      font-size: 14px;
      padding-left: 10px;
    }

    &-h2 {
      font-size: 30px;
      padding-left: 10px;
    }
  }

  .center-title-main {
    margin-bottom: 30px;
  }

  // project card page
  .project-card {
    margin-bottom: 100px;

    &-text {
      padding-top: 20px;
    }

    &-row {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      padding: 20px 30px;
      margin-bottom: 0px;
    }

    &-hr {
      color: $orange-codeploy;
      border: 1px solid;
      border-radius: 100px;
      max-width: 30px;
      margin: 0px;
    }

    &-h2 {
      color: $blue-codeploy;
      font-weight: 600;
      padding-top: 15px;
    }
  }

  // Blog pagina principale
 .blog-title-container {
   height: 80vh;
 }
  .heading-primary-center {
    font-size: 35px;
  }
  .blog-card {
    margin-top: 30vh;
  }

  // blog dettaglio
.header-blog {
  height: 50vh;
}
.blog_title .heading-primary {
	// height: 15vh;
  font-size: 25px;
  margin-top: 0px;
}
.heading-secondary {
	font-size: 15px;
}
.blog_description {
  max-width: 130vw;
}
.blog-text {
	margin-top: 100px;
}
.blog_img_div {
	margin-top: 0vh;
	padding-left: 5vw;
  visibility: hidden;
}
 .blog-body .header {
  height: 40vh;
}

.blog_title {
	padding-left: 6vw;
	margin-top: 20vh;
}

.blog_description {
	max-width: 130vw;
	padding-top: 20vh;
	margin-bottom: 10vh;
	padding-right: 20vw;
	padding-left: 20vw;
}
.blog-card-single-img {
  height: 100%;
}

.blog_img {
	max-width: 100%;
	max-height: 50vh;
	border-radius: 30px;
}


// pagina corso cloud
.servizi-title-training {
  font-size: 35px;
}
.servizi-container img {
  width: 250px;
  padding-top: 30px;
}
.goals-box-shadow  {
  margin-bottom: 30px;
}
.goals-box {
	&-text p {
		margin-left: 0px;
    margin-bottom: 0px;
	}
}
.goals-section {
	margin-top: 30px;
	padding-bottom: 30px;
}
.info-training img {
  width: 200px;
}
.info-training {
	margin-top: 30px;
	margin-bottom: 50px;
}
.training-partner img {
  padding-bottom: 30px;
}
.training-partner {
	padding-top: 80px;
	margin-bottom: 100px;
}
}

// pagina codesign 

#step .goals-box-img img {
	margin-left: 0px;
	margin-bottom: 25px;
}
#risultati {
  margin-bottom: 80px;
  padding: 60px 25px;
  img {
		margin-left: 7px;
		margin-top: 0px;
    width: 60px;
	}
}