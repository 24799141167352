h1,h2, h3, h4, h5, h6 {
    font-family: $h-font-family;
  }

  p {
    color: $grey-text;
  }

  li {
    list-style: none;
  }

.heading-primary {
    color: $blue-codeploy;
    font-size: 60px;
    font-weight: 700;

    &-center {
      font-size: 70px;
      color: $blue-codeploy;
      font-weight: 700;
      margin-top: 15px;
    }
}
.heading-primary-project {
  color: $blue-codeploy;
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 30px;
}

.yellow-text {
  color: $yellow-codeploy;
  font-weight: 800;
}
.title-section h2 {
  font-size: 38px;
}
.title-section-main {
  color: $yellow-codeploy;
  font-weight: 800;
}
.title-section-sub {
  color: #fff;
  font-weight: 600;
}

.description-section {
  &-h2 {
    color: $blue-codeploy;
    font-weight: 700;
    padding-bottom: 10px;
  }
  &-p {
    padding-right: 60px;
  }
  &-p-bold {
    color: $orange-codeploy;
    font-weight: 700;
  }
}

.center-title {
  padding-bottom: 50px;
  margin-top: 50px;
  &-main {
    color: $blue-codeploy;
    font-weight: 700;
  }
  &-sub {
    padding-top: 20px;
  }
  &-uppercase {
    color: $orange-codeploy;
    text-transform: uppercase;
    font-family: $font-family-sans-serif ;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
  }
  &-card {
    color: $blue-codeploy;
    font-weight: 600;
  }
 
}

.p-white {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}

.section-icon-card-h4 {
  color: $blue-codeploy;
  font-weight: 600;
  padding-top: 20px;
}

// product section with background 
.product-section-back-text {
  &-h4 {
    color: $yellow-codeploy;
    text-transform: uppercase;
    font-family: $font-family-sans-serif ;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    padding-bottom: 10px;
  }
  &-h2 {
    color: #fff;
    font-weight: 800;
    font-size: 40px;
    padding-right: 150px;
    letter-spacing: 3px;
  }
}

// product section without background 
.product-section-text {
  padding-left: 150px;
  &-h4 {
    color: $orange-codeploy;
    text-transform: uppercase;
    font-family: $font-family-sans-serif ;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    padding-bottom: 10px;
  }
  &-h2 {
    color: $blue-codeploy;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: 3px;
  }
}